import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import Card from "../../../components/customComponents/Card";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";
import Swal from "sweetalert2";
import axios from "axios";

function Chits() {
  const navigate = useNavigate();

  const { GetRequest, PostRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
  });
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function loadChits() {
      try {
        const response = await GetRequest(`/api/chit/all`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    loadChits();
    setLoaded(true);
  }, [GetRequest, navigate, reload]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const loadDisbursementByDate = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await GetRequest(
        `/api/chit/all/${userInput?.from}/${userInput?.to}`
      );

      if (response?.status === 200) {
        setData(response?.data);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDeletion = (id) => {
    Swal.fire({
      text: "Are you sure to delete this request?",
      title: "Question",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await PostRequest(`/api/chit/${id}/delete`);
        if (response?.status === 400) {
          Swal.fire("Action Failed", response?.data?.error_msg, "error");
        }

        if (response?.status === 201) {
          setReload(!reload);
          Swal.fire(
            "Success",
            "Request has been deleted successfully",
            "success"
          );
        }
      }
    });
  };

  const handlePayment = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Enter Total Chits Sold",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (totalSold) => {
        return axios
          .post("/api/chit/payment", { total_sold: totalSold, chit_id: id })
          .then((response) => {
            if (response.data.success) {
              Swal.fire(
                "Success",
                "Payment has been sent for approval",
                "success"
              );
            } else {
              Swal.fire("Error", "Error Recording Payment", "error");
            }
          })
          .catch((error) => {
            Swal.fire("Error", "An error occurred while processing.", "error");
          })
          .finally((_) => setReload(!reload));
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <div>
      <h1 className="h3 mb-3">Chit Management</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-md-4">
                <Card title={"Total Requests"} value={data?.length} />
              </div>
              <div className="col-md-4">
                <Card
                  title={"Total Pending"}
                  value={
                    data?.filter((item) => item.status !== "approved").length
                  }
                />
              </div>
              <div className="col-md-4">
                {" "}
                <Card
                  title={"Total Approved"}
                  value={
                    data?.filter((item) => item.status === "approved").length
                  }
                />
              </div>
            </div>

            <form onSubmit={loadDisbursementByDate}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    name="from"
                    value={userInput.from}
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    value={userInput.to}
                    name="to"
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <Button
                  cssClasses="btn btn-primary mt-3"
                  buttonText={"Load"}
                  isloading={btnLoading}
                />
              </div>
            </form>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">Disbuersement List</h5>
                      <button
                        className="btn btn-primary text-nowrap"
                        onClick={() => navigate(routes.CHIT_REQUEST)}
                      >
                        Make Chit Printout Request
                      </button>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 ">
                        <thead>
                          <tr>
                            <th>Sales Person</th>
                            <th>No. of Chits</th>
                            <th className="d-none d-md-table-cell">
                              Unit Price{" "}
                            </th>
                            <th className="d-none d-md-table-cell">
                              Expected Sales
                            </th>
                            <th className="d-none d-md-table-cell">
                              Total Sold
                            </th>
                            <th className="d-none d-md-table-cell">
                              Sales Amount
                            </th>
                            <th className="d-none d-md-table-cell">
                              Total Unsold
                            </th>
                            <th className="d-none d-md-table-cell">
                              Pending Sales
                            </th>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Status
                            </th>
                            <th className="d-none d-md-table-cell">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                            <tr key={index}>
                              <td className="text-capitalize">
                                {item?.staff?.fullname}
                              </td>
                              <td className="text-capitalize">
                                {item?.no_of_chits}
                              </td>
                              <td className="d-none d-md-table-cell">
                                GHC {item?.unit_price}
                              </td>
                              <td className="d-none d-md-table-cell">
                                GHC{" "}
                                {Number(
                                  item?.no_of_chits * item?.unit_price
                                ).toFixed(2)}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {item?.total_sold}
                              </td>
                              <td className="d-none d-md-table-cell">
                                GHC{" "}
                                {Number(
                                  item?.total_sold * item?.unit_price
                                ).toFixed(2)}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {Number(item?.total_unsold).toFixed(0)}
                              </td>
                              <td className="d-none d-md-table-cell">
                                GHC{" "}
                                {Number(
                                  item?.total_unsold * item?.unit_price
                                ).toFixed(2)}
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    item?.status === "approved"
                                      ? "bg-success"
                                      : "bg-danger"
                                  } `}
                                >
                                  {item?.status}
                                </span>
                              </td>

                              <td className="d-none d-md-table-cell text-nowrap">
                                {item?.status ===
                                  "pending_accountant_approval" && (
                                  <>
                                    <button
                                      className="btn btn-danger ms-2"
                                      onClick={() => handleDeletion(item?.id)}
                                    >
                                      <i
                                        className="fas fa-trash"
                                        title="Block"
                                      ></i>
                                    </button>
                                  </>
                                )}
                                {item?.status === "approved" &&
                                  Number(item?.is_printed) === Number(0) && (
                                    <>
                                      <button
                                        className="btn btn-success ms-2"
                                        onClick={() => {
                                          // sheets, id,amount
                                          localStorage.setItem(
                                            "sheets",
                                            item?.no_of_sheets
                                          );
                                          localStorage.setItem("id", item?.id);
                                          localStorage.setItem(
                                            "amount",
                                            item?.unit_price
                                          );
                                          navigate(routes.PRINT_CHIT);
                                        }}
                                      >
                                        <i
                                          className="fas fa-print"
                                          title="Print"
                                        ></i>
                                      </button>
                                    </>
                                  )}
                                {item?.status === "approved" &&
                                  Number(item?.is_printed) === Number("1") && (
                                    <>
                                      <button
                                        className="btn btn-primary ms-2"
                                        onClick={(e) =>
                                          handlePayment(e, item?.id)
                                        }
                                      >
                                        <i
                                          className="fa fa-credit-card"
                                          title="Record Payment"
                                        ></i>
                                      </button>
                                    </>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chits;
