import React from 'react'
import { useNavigate } from 'react-router-dom'

function Forbidden() {
    const navigate=useNavigate();
  return (
    <div className='text-center'>
    <h1 className='text-center'>
        This Action is forbidden
    </h1>
    <button onClick={()=>navigate(-3)} className='btn btn-primary'>Go Back</button>
    </div>
  )
}

export default Forbidden
