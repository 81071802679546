import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useApiContext } from "../../../components/context/ApiContext";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";
import Swal from "sweetalert2";

function PrintChit() {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !localStorage.getItem("sheets") ||
      !localStorage.getItem("id") ||
      !localStorage.getItem("amount")
    ) {
      navigate(routes.INITIAL_ROUTE);
    }

    if (!localStorage.getItem("token")) {
      navigate(routes.INITIAL_ROUTE);
    }
  }, [navigate]);
  // localStorage.setItem('sheets',item?.no_of_sheets)
  // localStorage.setItem('id',item?.id)
  // localStorage.setItem('amount',item?.unit_price)
  const ref = useRef();
  const { PostRequest } = useApiContext();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Chits",
    onAfterPrint: () => {
      const handleSubmit = async () => {
        try {
          const response = await PostRequest(
            `/api/chit/${localStorage.getItem("id")}/printed`
          );
          if (response?.status === 201) {
            navigate(routes.CHITS);
          } else if (response?.status === 400) {
            Swal.fire(
              "Error",
              response?.data?.error_msg || "Failed to print chit",
              "error"
            );
          } else if (response?.status === 422) {
            Swal.fire(
              "Error",
              response?.data?.errors || "Failed to print chit",
              "error"
            );
          }
        } catch (error) {
          Swal.fire("Error", "Failed to print chit", "error");
        }
      };
      handleSubmit();
    },
  });

  const chitComponents = [];

  for (let j = 0; j < Number(localStorage.getItem("sheets")); j++) {
    for (let i = 0; i < 26; i++) {
      chitComponents.push(<Chit key={i} />);
    }
  }

  return (
    <>
      <button className="btn btn-primary" onClick={handlePrint}>
        Print
      </button>
      <div className="container-fluid" ref={ref}>
        <div className="row">{chitComponents}</div>
      </div>
    </>
  );
}

const Chit = () => {
  const currentDate = new Date();

  // Extract individual components
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return (
    <div className="col-md-2 text-center mt-1">
      <img src="/img/placeholder.png" alt="logo" width={"70%"} />
      <div className="text-center">
        <h2>GHC {Number(localStorage.getItem("amount")).toFixed(2)}</h2>
        <small>{formattedDate}</small>
      </div>
    </div>
  );
};

export default PrintChit;
