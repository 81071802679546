import React, { useState } from "react";
import CheckListForm from "./partials/CheckListForm";
import Button from "../../../components/customComponents/Button";
import { useApiContext } from "../../../components/context/ApiContext";
import DeleteButton from "./partials/DeleteButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";

function CheckList() {
  const [foodItems, setFoodItems] = useState({});
  const [loading, setLoading] = useState(false);
  const { PostRequest } = useApiContext();
  const navigate = useNavigate();

  const handleApproval = async (e) => {
    e.preventDefault();
  
    if (!foodItems?.total_price||foodItems?.total_price < 1) {
      Swal.fire("Info", "Please Add items to the Checklist", "info");
      return;
    }

    try {
      const response = await PostRequest(`/api/canteen/food-list/approve`, {
        totalAmount: Number(foodItems?.total_price).toFixed(2),
        item_list_code: foodItems?.items[0]?.item_list_code,
      });

      if (response?.status === 201) {
        Swal.fire('Success',response?.data?.msg,'success')
        .then(_=>{
            navigate(routes.DISBURSEMENTS);
        })
      }
      if (response?.status === 400) {
        Swal.fire("Action Failed", response?.data?.error_msg, "error");
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
      Swal.fire("Failed", "Checklist Approval Failed", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Market Checklist</h1>

      <div className="row">
        <CheckListForm setFoodItems={setFoodItems} />
        <div className="col-xl-6 col-xxl-6">
          <div className="w-100">
            <div className="row">
              <div className="card p-3">
                <div className="card-title">
                  <div className="d-flex justify-content-between">
                    <div>Checklist Items</div>
                    <div>
                      Total Cost:{" "}
                      <b>₵ {Number(foodItems?.total_price || 0).toFixed(2)}</b>
                      <Button
                        buttonText={"Approve"}
                        cssClasses={"ms-2 btn btn-success"}
                        isloading={loading}
                        callback={handleApproval}
                      />
                    </div>
                  </div>
                </div>
                <div className="table table-responsive w-100">
                  <table className="table table-hover my-0 ">
                    <thead>
                      <tr>
                        <td className="d-none d-md-table-cell">Item</td>
                        <td className="d-none d-md-table-cell">Unit Price</td>
                        <td className="d-none d-md-table-cell">qty</td>
                        <td className="d-none d-md-table-cell">Total</td>
                        <td className="d-none d-md-table-cell">Actions</td>
                      </tr>
                    </thead>
                    <tbody>
                      {foodItems?.items?.map((item, index) => (
                        <tr key={index}>
                          <td className="d-none d-md-table-cell">
                            {item?.food_items}
                          </td>
                          <td className="d-none d-md-table-cell text-center">
                            ₵ {Number(item?.unit_price || 0).toFixed(2)}
                          </td>
                          <td className="d-none d-md-table-cell">
                            {item?.quantity}
                          </td>
                          <td className="d-none d-md-table-cell text-nowrap">
                            ₵ {Number(item?.total_price || 0).toFixed(2)}
                          </td>
                          <td>
                            <DeleteButton
                              id={item?.id}
                              setFoodItems={setFoodItems}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3} className="text-end">
                          Total
                        </td>
                        <td>
                          <b>
                            ₵ {Number(foodItems?.total_price || 0).toFixed(2)}
                          </b>
                        </td>
                        <td>
                          {" "}
                          <Button
                            buttonText={"Approve"}
                            cssClasses={"btn btn-success"}
                            isloading={loading}
                            callback={handleApproval}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckList;
