import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../components/strings/routes";
import Button from "../../../components/customComponents/Button";
import { useApiContext } from "../../../components/context/ApiContext";

function EditFoodItem() {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    name: "",
    food_categories_id: "",
    unit_price: "",
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const { PostRequest, GetRequest } = useApiContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await PostRequest(`/api/food-item/${id}/update`, userInput);
    if (res?.status === 201) {
      navigate(routes.FOOD_ITEMS);
    }

    if (res?.status === 422) {
      setErrors(res?.data?.errors);
    }

    setLoading(false);
  };

  const { id } = useParams();

  useEffect(() => {
    async function getCategories() {
      try {
        const response = await GetRequest(`/api/food-item/categories`);
        if (response?.status === 200) {
          setCategories(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    async function getFoodItem() {
      try {
        const response = await GetRequest(`/api/food-item/${id}/edit`);
        if (response?.status === 200) {
          setUserInput(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getCategories();
    getFoodItem();
  }, [GetRequest, id, navigate]);

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add Food Stuff</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={userInput?.name}
                      onChange={handleUserInput}
                      required
                      maxLength={150}
                      placeholder="Enter name"
                    />
                    <small className="text-danger">{errors?.name}</small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="food_categories_id">Select Category:</label>
                    <select
                      className="form-control"
                      required
                      name="food_categories_id"
                      value={userInput?.food_categories_id}
                      onChange={handleUserInput}
                    >
                      <option value={""}>Select Category</option>
                      {categories?.map((item, index) => (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {errors?.food_categories_id}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="unit_price">Unit Price:</label>
                    <input
                      type="number"
                      className="form-control"
                      name="unit_price"
                      min={0}
                      step={"any"}
                      value={userInput?.unit_price}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter Unit Price"
                    />
                    <small className="text-danger">{errors?.unit_price}</small>
                  </div>

                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Save"}
                    isloading={isloading}
                    callback={handleSubmit}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-2 ms-2"
                    onClick={() => navigate(routes.USERS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFoodItem;
