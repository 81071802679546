import React, { useState } from "react";
import { useApiContext } from "../../../../components/context/ApiContext";
import Spinners from "../../../../components/helpers/Spinners";

function DeleteButton({ id, setFoodItems }) {
  const { PostRequest } = useApiContext();
  const [isloading, setLoading] = useState(false);

  const handleDeletion = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await PostRequest(`/api/canteen/food-list/${id}/delete`);
    if (response?.status === 200) {
      setFoodItems(response?.data);
    }

    setLoading(false);
  };
  return (
    <button className="btn btn-danger ms-2" onClick={handleDeletion}>
       {!isloading ? <i className="fas fa-trash" title="Block"></i> : <>{Spinners.rotatingLines} Please Wait...</>}
    </button>

  );
}

export default DeleteButton;
