import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import Card from "../../../components/customComponents/Card";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";
import Swal from "sweetalert2";

function Disbursement() {
  const navigate = useNavigate();

  const { GetRequest,PostRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
  });
  const [reload,setReload]=useState(false);

  useEffect(() => {
    async function getDisbursementList() {
      try {
        const response = await GetRequest(`/api/approvals/disbursement`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getDisbursementList();
    setLoaded(true);
  }, [GetRequest, navigate,reload]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const loadDisbursementByDate = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await GetRequest(
        `/api/approvals/disbursement/${userInput?.from}/${userInput?.to}`
      );
     
      if (response?.status === 200) {
        setData(response?.data);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDeletion=(id)=>{
    Swal.fire({
        text:'Are you sure to delete this request?',
        title:'Question',
        showConfirmButton:true,
        confirmButtonText:'Yes',
        showDenyButton:true,
        denyButtonText:'No',
    }).then(async(result)=>{
        if(result.isConfirmed){
            const response=await PostRequest(`/api/approvals/${id}/delete`);
           if(response?.status===400){
            Swal.fire('Action Failed',response?.data?.error_msg,'error');
           }

           if(response?.status===201){
            setReload(!reload);
            Swal.fire('Success','Request has been deleted successfully','success')
           }
        }
    })
  }

  return (
    <div>
      <h1 className="h3 mb-3">Disbursement Request(s)</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-md-4">
                <Card title={"Total Requests"} value={data?.length} />
              </div>
              <div className="col-md-4">
                <Card
                  title={"Total Pending"}
                  value={
                    data?.filter((item) => item.status !== "approved").length
                  }
                />
              </div>
              <div className="col-md-4">
                {" "}
                <Card
                  title={"Total Approved"}
                  value={
                    data?.filter((item) => item.status === "approved").length
                  }
                />
              </div>
            </div>

            <form onSubmit={loadDisbursementByDate}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    name="from"
                    value={userInput.from}
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    value={userInput.to}
                    name="to"
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <Button
                  cssClasses="btn btn-primary mt-3"
                  buttonText={"Load"}
                  isloading={btnLoading}
                />
              </div>
            </form>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">Disbuersement List</h5>
                      <button
                        className="btn btn-primary text-nowrap"
                        onClick={() => navigate(routes.DISBURSEMENT_REQUEST)}
                      >
                        Make Disbuersement Request
                      </button>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 ">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th className="d-none d-md-table-cell">Amount </th>
                            <th className="d-none d-md-table-cell">Status</th>
                            <th className="d-none d-md-table-cell">
                              Accountant Approval By
                            </th>
                            <th className="d-none d-md-table-cell">
                              Accountant Approval Date
                            </th>
                            <th className="d-none d-md-table-cell">
                              Admin Approval By
                            </th>
                            <th>Admin Approval Date</th>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Created By
                            </th>
                            <th className="d-none d-md-table-cell">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                            <tr key={index}>
                              <td className="text-capitalize">
                                {item?.description}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {item?.amount}
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    item?.status === "approved"
                                      ? "bg-success"
                                      : "bg-danger"
                                  } `}
                                >
                                  {item?.status}
                                </span>
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.accountant?.name || "Pending"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.accountant_approval_date || "N/A"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.admin?.name || "Pending"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.admin_approval_date || "N/A"}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {item?.created_by?.name}
                              </td>
                              <td className="d-none d-md-table-cell text-nowrap">
                                {item?.status ===
                                  "pending_accountant_approval" && (
                                  <>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() =>
                                        navigate(
                                          `/dashboard/disbursement-request/${item?.id}/edit`
                                        )
                                      }
                                    >
                                      <i
                                        className="fas fa-pencil"
                                        title="Edit Request"
                                      ></i>
                                    </button>
                                    <button className="btn btn-danger ms-2" onClick={()=>handleDeletion(item?.id)}>
                                      <i
                                        className="fas fa-trash"
                                        title="Block"
                                      ></i>
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disbursement;
