import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import { useApiContext } from "../../../components/context/ApiContext";

function StaffList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { GetRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  useEffect(() => {
    async function loadUsers() {
      try {
        const response = await GetRequest("/api/staff/all");
        setData(response?.data);
        setLoaded(true);
      } catch (error) {
        console.log("Error fetching school details:", error);
        setLoaded(true);
      }
    }

    loadUsers();
  }, [GetRequest]);

  return (
    <div>
      <h1 className="h3 mb-3">Manage Staff</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Staff</h5>
                      <div className="d-flex flex-row gap-3">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={() => navigate(routes.ADD_STAFF)}
                        >
                          Add New Staff
                        </button>
                      </div>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 ">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th className="d-none d-md-table-cell">Phone </th>
                            <th className="d-none d-md-table-cell">Role</th>
                            <th className="d-none d-md-table-cell">
                              Residence
                            </th>
                            <th className="d-none d-md-table-cell">
                              Emerg. Contact Name
                            </th>
                            <th className="d-none d-md-table-cell">
                              Emerg. Contact Number
                            </th>
                            <th>Status</th>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Created By
                            </th>
                            <th className="d-none d-md-table-cell">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data
                            ?.filter((item) => {
                              if (search === "") {
                                return item;
                              }

                              return (
                                item?.fullname
                                  ?.toLowerCase()
                                  ?.includes(search.toLowerCase()) ||
                                item?.role
                                  ?.toLowerCase()
                                  ?.includes(search.toLowerCase())
                              );
                            })
                            ?.map((user, index) => (
                              <tr key={index}>
                                <td className="text-capitalize">
                                  {user?.fullname}
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {user?.telephone}
                                </td>
                                <td className="d-none d-md-table-cell text-capitaliz">
                                  {user?.role}
                                </td>
                                <td className="d-none d-md-table-cell text-capitaliz">
                                  {user?.residence}
                                </td>
                                <td className="d-none d-md-table-cell text-capitaliz">
                                  {user?.emergency_contact_name}
                                </td>
                                <td className="d-none d-md-table-cell text-capitaliz">
                                  {user?.emergency_contact_number}
                                </td>
                                <td>
                                  <span
                                    className={`badge ${
                                      user?.status === "active"
                                        ? "bg-success"
                                        : "bg-danger"
                                    } `}
                                  >
                                    {user?.status}
                                  </span>
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {user?.created_by?.name}
                                </td>
                                <td className="d-none d-md-table-cell text-nowrap">
                                  <button className="btn btn-secondary">
                                    <i
                                      className="fas fa-pencil"
                                      title="Edit User"
                                    ></i>
                                  </button>
                                  <button className="btn btn-danger ms-2">
                                    <i
                                      className="fas fa-trash"
                                      title="Block"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffList;
