import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";
import Swal from "sweetalert2";

function FoodItems() {
  const navigate = useNavigate();

  const { GetRequest,PostRequest } = useApiContext();
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [search, setSearch] = useState("");

  const [reload,setReload] = useState(false);

  useEffect(() => {
    async function getApprovalList() {
      try {
        const response = await GetRequest(`/api/food-item/all`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    async function getCategories() {
      try {
        const response = await GetRequest(`/api/food-item/categories`);
        if (response?.status === 200) {
          setCategories(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getApprovalList();
    getCategories();
    setLoaded(true);
  }, [GetRequest, navigate, reload]);

  const handleDelete=(e,id)=>{
    e.preventDefault();
    Swal.fire({
        title:'Question',
        text:'Are you sure to delete this item?',
        showDenyButton:true,
        showConfirmButton:true,
        confirmButtonText:'Yes',
        denyButtonText:"No"
    }).then(async(result)=>{
        if(result.isConfirmed){
            try {
                const response = await PostRequest(`/api/food-item/${id}/delete`);
                if (response?.status === 200 || response?.status === 201) {
                  setReload(!reload);
                }
              } catch (error) {
                Swal.fire('Action Failed','Failed to delete food item','error');
              }
            }
        }
    )
  }

  return (
    <div>
      <h1 className="h3 mb-3">Food Stuffs</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="d-flex justify-content-between gap-3 mb-3 align-items-center">
              <div className="form-group d-flex gap-3 align-items-center">
                <label htmlFor="from" className="text-nowrap">
                  Sort by Category
                </label>
                <select
                  className="form-control"
                  required
                  name="status"
                  onChange={(e) => setSearch(e.target.value)}
                >
                  <option value={""}>Select Category</option>
                  {categories?.map((item, index) => (
                    <option value={item?.name} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <Button
                cssClasses="btn btn-primary"
                buttonText={"Add Food Stuff"}
                callback={() => navigate(routes.ADD_FOOD_ITEM)}
              />
            </div>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">Food Stuff Listing</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 ">
                        <thead>
                          <tr>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Name
                            </th>
                            <th className="d-none d-md-table-cell">
                              Unit Price
                            </th>
                            <th className="d-none d-md-table-cell">
                              Category{" "}
                            </th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data
                            ?.filter((item) => {
                              if (search === "") {
                                return item;
                              }

                              return (
                                item?.name
                                  ?.toLowerCase()
                                  ?.includes(search.toLowerCase()) ||
                                item?.category?.name
                                  ?.toLowerCase()
                                  ?.includes(search.toLowerCase())
                              );
                            })
                            ?.map((item, index) => (
                              <tr key={index}>
                                <td className="d-none d-md-table-cell">
                                  {item?.name}
                                </td>
                                <td className="text-capitalize">
                                  GHS {Number(item?.unit_price).toFixed(2)}
                                </td>
                                <td className="d-none d-md-table-cell">
                                  {item?.category?.name}
                                </td>

                                <td className="d-none d-md-table-cell text-capitalize">
                                  <button className="btn btn-secondary" onClick={()=>navigate(`/dashboard/food/${item?.id}/edit`)}>
                                    <i
                                      className="fas fa-pen"
                                      title="Edit"
                                    ></i>
                                  </button>
                                  <button className="btn btn-danger ms-2" onClick={(e)=>handleDelete(e,item?.id)}>
                                    <i
                                      className="fas fa-trash"
                                      title="Delete"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodItems;
