import React from "react";

function TransactionDetails({ transaction, setScreen }) {


  return (
    <div>
      <h1 className="h3 mb-3">Transaction Details</h1>

      <div className="row">
        <div className="col-12d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="d-flex flex-row justify-content-between">
                <h5 className="card-title mb-0"><button className="btn btn-secondary" onClick={()=>setScreen('transactions')}>Back</button></h5>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label for="">Staff</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={transaction?.approvals?.staff?.fullname}
                />
              </div>
              <div className="form-group mt-4">
                <label for="">Transaction Description</label>
                <textarea cols={20} rows={10} className="form-control" readOnly>
                  {transaction?.approvals?.description}
                </textarea>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="form-group">
                    <label for="">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={ Number(transaction?.debit).toFixed(2) > 0
                        ? Number(transaction?.debit).toFixed(2)
                        : Number(transaction?.credit).toFixed(2)}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label for="">Status</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={transaction?.status}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label for="">Transaction Type</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={transaction?.approvals?.type}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label for="">Transaction Date</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={transaction?.approvals?.admin_approval_date}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetails;
