import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import Card from "../../../components/customComponents/Card";
import { useApiContext } from "../../../components/context/ApiContext";

function Users() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);

  const { GetRequest } = useApiContext();

  useEffect(() => {
    async function loadUsers() {
      try {
        const response = await GetRequest("/api/auth/users");
        setData(response?.data);
        setLoaded(true);
      } catch (error) {
        console.log("Error fetching school details:", error);
        setLoaded(true);
      }
    }

    loadUsers();
  }, [GetRequest]);



  return (
    <div>
      <h1 className="h3 mb-3">Manage Users</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-md-3">
                <Card title={"Total Users"} value={data?.length} />
              </div>
              <div className="col-md-3">
                <Card title={"Admins"} value={data?.filter(item=>item?.role==='admin').length} />
              </div>
              <div className="col-md-3">
                {" "}
                <Card title={"Accountant"} value={data?.filter(item=>item?.role==='accountant').length} />
              </div>
              <div className="col-md-3">
                {" "}
                <Card title={"Accountant"} value={data?.filter(item=>item?.role==='domestic').length} />
              </div>
            </div>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">List of Users</h5>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate(routes.CREATE_USERS)}
                      >
                        Add New User
                      </button>
                    </div>
                  </div>
                  {isloaded ? (
                    <table className="table table-hover my-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="d-none d-md-table-cell">Email </th>
                          <th className="d-none d-md-table-cell">phone</th>
                          <th>Status</th>
                          <th className="d-none d-md-table-cell">Role</th>
                          <th className="d-none d-md-table-cell">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((user, index) => (
                          <tr key={index}>
                            <td className="text-capitalize">{user?.name}</td>
                            <td className="d-none d-md-table-cell">
                              {user?.email}
                            </td>
                            <td className="d-none d-md-table-cell">
                              {user?.phone}
                            </td>
                            <td>
                              <span
                                className={`badge text-capitalize ${
                                  user?.status === "active"
                                    ? "bg-success"
                                    : "bg-danger"
                                } `}
                              >
                                {user?.status}
                              </span>
                            </td>
                            <td className="d-none d-md-table-cell text-capitalize">
                              {user?.role}
                            </td>
                            <td className="d-none d-md-table-cell text-nowrap">
                              <button className="btn btn-secondary">
                                <i
                                  className="fas fa-pencil"
                                  title="Edit User"
                                ></i>
                              </button>
                              <button className="btn btn-danger ms-2">
                                <i className="fas fa-trash" title="Block"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
