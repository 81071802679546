import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import Card from "../../../components/customComponents/Card";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";

function StaffStatement() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { GetRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
    status: "",
  });
  const [reload] = useState(false);

  useEffect(() => {
    async function getApprovalList() {
      try {
        const response = await GetRequest(`/api/transactions/log`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getApprovalList();
    setLoaded(true);
  }, [GetRequest, navigate, reload]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const loadApprovals = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await GetRequest(
        `api/transactions/staff/${id}/log/${userInput?.from}/${userInput?.to}/${userInput?.status}`
      );

      if (response?.status === 200) {
        setData(response?.data);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="h3 mb-3">Staff Transaction Statement</h1>
        <button className="btn btn-secondary" onClick={()=>navigate(routes.STAFF_BALANCES)}>Go Back</button>
      </div>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <div className="row">
              <div className="col-md-4">
                <Card
                  title={"Total Payment Recieved"}
                  value={data?.disbursements}
                />
              </div>
              <div className="col-md-4">
                <Card
                  title={"Total Payments Made"}
                  value={data?.payment_recieved}
                />
              </div>
              <div className="col-md-4">
                {" "}
                <Card title={"Surplus/Deficit"} value={data?.balance} />
              </div>
            </div>

            <form onSubmit={loadApprovals}>
              <div className="d-flex gap-3 mb-3 align-items-center">
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    name="from"
                    value={userInput.from}
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="from">To</label>
                  <input
                    type="date"
                    className="form-control"
                    value={userInput.to}
                    name="to"
                    onChange={handleUserInput}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="from">Status</label>
                  <select
                    className="form-control"
                    required
                    name="status"
                    value={userInput?.status}
                    onChange={handleUserInput}
                  >
                    <option value={""}>Select Status</option>
                    <option value={"all"}>All</option>
                    <option value={"disbursement"}>Disbursement</option>
                    <option value={"sales"}>Sales</option>
                  </select>
                </div>
                <Button
                  cssClasses="btn btn-primary mt-3"
                  buttonText={"Load"}
                  isloading={btnLoading}
                />
              </div>
            </form>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">Transaction Log</h5>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 ">
                        <thead>
                          <tr>
                            <th className="d-none d-md-table-cell text-nowrap">
                              ID
                            </th>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Initiated By
                            </th>
                            <th>Description</th>
                            <th className="d-none d-md-table-cell">
                              Transaction type{" "}
                            </th>
                            <th className="d-none d-md-table-cell">Amount </th>
                            <th className="d-none d-md-table-cell">
                              Payment to/by
                            </th>
                            <th className="d-none d-md-table-cell">
                              Accountant Approval By
                            </th>
                            <th className="d-none d-md-table-cell">
                              Accountant Approval Date
                            </th>
                            <th className="d-none d-md-table-cell">
                              Admin Approval By
                            </th>
                            <th>Admin Approval Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.transactions?.map((item, index) => (
                            <tr key={index}>
                              <td className="d-none d-md-table-cell">
                                {item?.id}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {item?.approvals?.created_by?.name}
                              </td>
                              <td className="text-capitalize">
                                {item?.approvals?.description}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {item?.type}
                              </td>
                              <td className="d-none d-md-table-cell">
                                {Number(item?.debit).toFixed(2) > 0
                                  ? Number(item?.debit).toFixed(2)
                                  : Number(item?.credit).toFixed(2)}
                              </td>
                              <td>{item?.approvals?.staff?.fullname}</td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.approvals?.accountant?.name || "N/A"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.approvals?.accountant_approval_date ||
                                  "N/A"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.approvals?.admin?.name || "Pending"}
                              </td>
                              <td className="d-none d-md-table-cell text-capitaliz">
                                {item?.approvals?.admin_approval_date || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffStatement;
