import React from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Authentication/Login";
import routes from "./components/strings/routes";
import endpoints from "./components/strings/endpoints";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Dashboard/Home";
import Users from "./pages/Dashboard/Users/Users";
import AddUser from "./pages/Dashboard/Users/AddUser";
import StaffList from "./pages/Dashboard/Staff/StaffList";
import AddStaff from "./pages/Dashboard/Staff/AddStaff";
import ManageApprovals from "./pages/Dashboard/Approvals/ManageApprovals";
import { ApiContextProvider } from "./components/context/ApiContext";
import Disbursement from "./pages/Dashboard/Disbursement/Disbursement";
import DisbursementRequest from "./pages/Dashboard/Disbursement/DisbursementRequest";
import EditDisbursementRequest from "./pages/Dashboard/Disbursement/EditDisbursementRequest";
import Forbidden from "./pages/Others/Forbidden";
import Transaction from "./pages/Dashboard/transactions/Transaction";
import StaffBalances from "./pages/Dashboard/StaffBalances/StaffBalances";
import StaffStatement from "./pages/Dashboard/StaffBalances/StaffStatement";
import FoodItems from "./pages/Dashboard/FoodItems/FoodItems";
import AddFoodItem from "./pages/Dashboard/FoodItems/AddFoodItem";
import EditFoodItem from "./pages/Dashboard/FoodItems/EditFoodItem";
import CheckList from "./pages/Dashboard/Checklist/Checklist";
import Chits from "./pages/Dashboard/Chits/Chits";
import CreateChit from "./pages/Dashboard/Chits/CreateChit";
import PrintChit from "./pages/Dashboard/Chits/PrintChit";
import BalanceWaiver from "./pages/Dashboard/StaffBalances/BalanceWaiver";

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.baseURL = `${endpoints.BASE_URL}`;

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return (
    <ApiContextProvider>
      <Routes>
        <Route path={routes.INITIAL_ROUTE} element={<Login />} />
        <Route path={routes.DASHBOARD} element={<Dashboard />}>
          <Route path={routes.DASHBOARD} element={<Home />} />
          <Route path={routes.USERS} element={<Users />} />
          <Route path={routes.CREATE_USERS} element={<AddUser />} />
          <Route path={routes.STAFF_LIST} element={<StaffList />} />
          <Route path={routes.ADD_STAFF} element={<AddStaff />} />
          <Route path={routes.MANAGE_APPROVALS} element={<ManageApprovals />} />
          <Route path={routes.CHITS} element={<Chits />} />
          <Route path={routes.CHIT_REQUEST} element={<CreateChit />} />
          <Route path={routes.DISBURSEMENTS} element={<Disbursement />} />
          <Route
            path={routes.DISBURSEMENT_REQUEST}
            element={<DisbursementRequest />}
          />
          <Route
            path={routes.EDIT_DISBURSEMENT_REQUEST}
            element={<EditDisbursementRequest />}
          />
          <Route path={routes.TRANSACTIONS} element={<Transaction />} />
          <Route path={routes.STAFF_BALANCES} element={<StaffBalances />} />
          <Route path={routes.STAFF_STATEMENT} element={<StaffStatement />} />
          <Route path={routes.FOOD_ITEMS} element={<FoodItems />} />
          <Route path={routes.ADD_FOOD_ITEM} element={<AddFoodItem />} />
          <Route path={routes.EDIT_FOOD_ITEM} element={<EditFoodItem />} />
          <Route path={routes.CHECKLIST} element={<CheckList />} />
          <Route path={routes.BALANCE_WAIVER} element={<BalanceWaiver />} />
        </Route>
        <Route path={routes.PRINT_CHIT} element={<PrintChit />} />
        <Route path={routes.FORBIDDEN} element={<Forbidden />} />
      </Routes>
    </ApiContextProvider>
  );
}

export default App;
