import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";
import Button from "../../../components/customComponents/Button";
import { useApiContext } from "../../../components/context/ApiContext";

function AddStaff() {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    fullname: "",
    telephone: "",
    residence: "",
    emergency_contact_name: "",
    emergency_contact_number: "",
    role: "",
    status:"active",
    errors: {},
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const {PostRequest}=useApiContext();

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    const response=await PostRequest('/api/staff/create',userInput);
    console.log(response);
    if(response?.status===201){
      navigate(routes.STAFF_LIST);
    }
    if(response?.status===422){
      setUserInput(userInput=>({
        ...userInput,errors:response?.data?.errors
      }))
    }
    setLoading(false);
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Add New Staff</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Enter Full Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="fullname"
                      value={userInput?.fullname}
                      onChange={handleUserInput}
                      required
                      maxLength={150}
                      placeholder="Enter Full Name"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.fullname}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="email">Enter Mobile Number:</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="telephone"
                      value={userInput?.telephone}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter Mobile Number"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.telephone}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="residence">Residence:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="residence"
                      value={userInput?.residence}
                      onChange={handleUserInput}
                      required
                      maxLength={150}
                      placeholder="Enter Residence"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.residence}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="emergency_contact_name">Emergency Contact Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="emergency_contact_name"
                      value={userInput?.emergency_contact_name}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter emergency_contact_name "
                    />
                    <small className="text-danger">
                      {userInput?.errors?.emergency_contact_name}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="emergency_contact_number">Emergency Contact Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="emergency_contact_number"
                      value={userInput?.emergency_contact_number}
                      onChange={handleUserInput}
                      required
                      placeholder="Enter Emergency Contact number"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.emergency_contact_number}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="role">Role:</label>
                    <select
                      className="form-control"
                      name="role"
                      value={userInput?.role}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="mart_attendant">Mart Attendant</option>
                      <option value="food_vendor">Food Vendor</option>
                      <option value="canteen_staff">Canteen Staff</option>
                      <option value="chit_staff">Chit Staff</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.role}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="role">Status:</label>
                    <select
                      className="form-control"
                      name="status"
                      value={userInput?.status}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="blocked">Blocked</option>
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.status}
                    </small>
                  </div>
                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Save"}
                    isloading={isloading}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-2 ms-2"
                    onClick={() => navigate(routes.USERS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStaff;
