import React, { useState } from "react";
import Button from "../../../components/customComponents/Button";
import { useApiContext } from "../../../components/context/ApiContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/strings/routes";

function RecordSales({ staff_id }) {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { PostRequest } = useApiContext();
  const handleClicked = (e) => {
    e.preventDefault();
    setClicked(true);
  };

  const [amount, setAmount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Number(amount) || amount < 1) {
      window.alert("Please Enter amount paid");
      return;
    }

    setLoading(true);
    try {
      const response = await PostRequest(`/api/transactions/staff/repayment`, {
        staff_id: staff_id,
        amount: amount,
      });

      if (response?.status === 200) {
        Swal.fire(
          "Success",
          "Payment has been recorded and sent for approval",
          "success"
        );
        setClicked(false);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      Swal.fire("Error", "Error Recording Payment", "error");
      setClicked(false);
    } finally {
      setLoading(false);
      setClicked(false);
      setAmount(0)
    }
  };

  return (
    <>
      {!clicked ? (
        <button class="btn btn-primary" onClick={handleClicked}>
          <i class="fas fa-book" title="Approve Request"></i> Record Payment
        </button>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="number"
              required
              className="form-control"
              placeholder="Enter amount Here"
              value={amount}
              onChange={(e)=>setAmount(e.target.value)}
              min={0}
              step='any'
            />
            <Button
              cssClasses={"btn btn-primary"}
              buttonText={"Save"}
              isloading={loading}
            />
          </div>
        </form>
      )}
    </>
  );
}

export default RecordSales;
