import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";
import RecordSales from "./RecordSales";

function StaffBalances() {
  const navigate = useNavigate();

  const { GetRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
    status: "",
  });
  const [reload] = useState(false);

  useEffect(() => {
    async function getApprovalList() {
      try {
        const response = await GetRequest(`/api/staff/all`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getApprovalList();
    setLoaded(true);
  }, [GetRequest, navigate, reload]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const loadApprovals = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await GetRequest(
        userInput.status === "all"
          ? `/api/staff/all`
          : `/api/staff/all/${userInput?.status}`
      );

      if (response?.status === 200) {
        setData(response?.data);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <h1 className="h3 mb-3">Staff Balances</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex">
          <div className="w-100">
            <form onSubmit={loadApprovals}>
              <div className="d-flex justify-content-center gap-3 mb-3 align-items-center">
                <div className="form-group d-flex gap-3 align-items-center">
                  <label htmlFor="from" className="text-nowrap">
                    Select Staff Type
                  </label>
                  <select
                    className="form-control"
                    required
                    name="status"
                    value={userInput?.status}
                    onChange={handleUserInput}
                  >
                    <option value={""}>Select Status</option>
                    <option value={"all"}>All</option>
                    <option value="mart_attendant">Mart Attendant</option>
                    <option value="food_vendor">Food Vendor</option>
                    <option value="canteen_staff">Canteen Staff</option>
                  </select>
                </div>
                <Button
                  cssClasses="btn btn-primary"
                  buttonText={"Load"}
                  isloading={btnLoading}
                />
              </div>
            </form>

            <div className="row">
              <div className="col-12d-flex">
                <div className="card flex-fill">
                  <div className="card-header">
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className="card-title mb-0">Staff List</h5>
                    </div>
                  </div>
                  {isloaded ? (
                    <div className="table-responsive">
                      <table className="table table-hover my-0 table-bordered">
                        <thead>
                          <tr>
                            <th className="d-none d-md-table-cell text-nowrap">
                              Full Name
                            </th>
                            <th className="d-none d-md-table-cell">Role</th>
                            <th className="d-none d-md-table-cell">Status </th>
                            <th className="d-none d-md-table-cell text-center">
                              Debit(Deposit)
                            </th>
                            <th className="d-none d-md-table-cell text-center">
                              Credit(Payments)
                            </th>
                            <th className="d-none d-md-table-cell text-center">
                              (Surplus/Deficit)
                            </th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => (
                            <tr key={index}>
                              <td className="d-none d-md-table-cell">
                                {item?.fullname}
                              </td>
                              <td className="text-capitalize">{item?.role}</td>
                              <td
                                className={`d-none d-md-table-cell ${
                                  item?.status === "active"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {item?.status}
                              </td>
                              <td className="d-none d-md-table-cell text-center">
                                {Number(item?.wallet?.debit || 0)?.toFixed(2)}
                              </td>
                              <td className="d-none d-md-table-cell text-center">
                                {Number(item?.wallet?.credit || 0)?.toFixed(2)}
                              </td>
                              <td
                                className={`d-none d-md-table-cell text-center ${
                                  Number(
                                    item?.wallet?.debit - item?.wallet?.credit
                                  ) > 0
                                    ? "text-white bg-danger"
                                    : "bg-success"
                                }`}
                              >
                                GHS{" "}
                                {Number(
                                  item?.wallet?.debit - item?.wallet?.credit ||
                                    0
                                ).toFixed(2)}
                              </td>

                              <td className="d-none d-md-table-cell text-capitalize">
                                <div className="d-flex flex-column gap-2">
                                  <button
                                    class="btn btn-info"
                                    onClick={() =>
                                      navigate(
                                        `/dashboard/staff/${item?.id}/statement`
                                      )
                                    }
                                  >
                                    <i
                                      class="fas fa-eye"
                                      title="Approve Request"
                                    ></i>{" "}
                                    View Statement
                                  </button>
                                  {localStorage.getItem("role") ===
                                    "domestic" && (
                                    <RecordSales staff_id={item?.id} />
                                  )}
                                  {localStorage.getItem("role") === "admin" && (
                                    <Button
                                      cssClasses="btn btn-primary"
                                      buttonText={"Waive Balance"}
                                      callback={()=>navigate(`/dashboard/staff/${item?.id}/balance-waiver`)}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <LoadingPage />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffBalances;
