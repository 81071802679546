import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../components/strings/routes";
import Button from "../../../components/customComponents/Button";
import { useApiContext } from "../../../components/context/ApiContext";
import Swal from "sweetalert2";

function EditDisbursementRequest() {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    staff_id: "",
    amount: "",
    description: "",
    errors: {},
  });

  const [staff, setStaff] = useState([]);
  const { GetRequest } = useApiContext();
  const { id } = useParams();

  useEffect(() => {
    async function getStaff() {
      try {
        const response = await GetRequest(`/api/staff/all`);
        setStaff(response?.data);
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    async function getDisbursementRecord() {
      try {
        const response = await GetRequest(`/api/approvals/${id}/edit`);
        setUserInput(response?.data);
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getStaff();
    getDisbursementRecord();
  }, [GetRequest, id]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const { PostRequest } = useApiContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await PostRequest(
      `/api/approvals/${id}/update`,
      userInput
    );
    if (response?.status === 201) {
      navigate(routes.DISBURSEMENTS);
    }
    if (response?.status === 400) {
      setUserInput((userInput) => ({
        ...userInput,
        errors: response?.data?.error_msg,
      }));
      Swal.fire(
        "Update Failed",
        response?.data?.error_msg?.staff_id?.[0],
        "error"
      );
    }
    if (response?.status === 422) {
      setUserInput((userInput) => ({
        ...userInput,
        errors: response?.data?.errors,
      }));
    }
    setLoading(false);
  };

  return (
    <div>
      <h1 className="h3 mb-3 text-center">Edit Disbuersement Request</h1>

      <div className="row">
        <div className="col-xl-12 col-xxl-12 d-flex justify-content-center">
          <div className="w-75">
            <div className="row">
              <div className="card p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mt-2">
                    <label htmlFor="staff_id">Select Staff:</label>
                    <select
                      className="form-control"
                      name="staff_id"
                      value={userInput?.staff_id}
                      onChange={handleUserInput}
                      required
                    >
                      <option value="">Select Staff</option>
                      {staff?.map((item, index) => (
                        <option
                          className="text-capitalize"
                          key={index}
                          value={item?.id}
                        >
                          {item?.fullname}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {userInput?.errors?.staff_id}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="email">Enter Disbursement Amount:</label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      step={"any"}
                      value={userInput?.amount}
                      onChange={handleUserInput}
                      required
                      min={1}
                      placeholder="Enter Disbursement Amount"
                    />
                    <small className="text-danger">
                      {userInput?.errors?.amount}
                    </small>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="description">Add Description Here:</label>
                    <textarea
                      className="form-control"
                      name="description"
                      rows={10}
                      cols={15}
                      value={userInput?.description}
                      onChange={handleUserInput}
                      minLength={15}
                      maxLength={250}
                    ></textarea>

                    <small className="text-danger">
                      {userInput?.errors?.description}
                    </small>
                  </div>

                  <Button
                    cssClasses={"btn btn-primary mt-2"}
                    buttonText={"Save"}
                    isloading={isloading}
                  />
                  <button
                    type="submit"
                    className="btn btn-secondary mt-2 ms-2"
                    onClick={() => navigate(routes.DISBURSEMENTS)}
                  >
                    Go Back
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditDisbursementRequest;
