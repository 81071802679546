import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingPage from "../../../components/customComponents/LoadingPage";
import routes from "../../../components/strings/routes";
import Card from "../../../components/customComponents/Card";
import { useApiContext } from "../../../components/context/ApiContext";
import Button from "../../../components/customComponents/Button";
import Swal from "sweetalert2";
import ApprovalDetails from "./ApprovalDetails";

function ManageApprovals() {
  const navigate = useNavigate();

  const { GetRequest, PostRequest } = useApiContext();
  const [data, setData] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    from: "",
    to: "",
    status: "",
  });
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function getApprovalList() {
      try {
        const response = await GetRequest(`api/approvals/pending`);
        if (response?.status === 200) {
          setData(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    getApprovalList();
    setLoaded(true);
  }, [GetRequest, navigate, reload]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const loadApprovals = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const response = await GetRequest(
        `api/approvals/pending/${userInput?.from}/${userInput?.to}/${userInput?.status}`
      );

      if (response?.status === 200) {
        setData(response?.data);
      }
      if (response?.status === 403 || response?.status === 405) {
        navigate(routes?.FORBIDDEN);
      }
    } catch (error) {
      console.log("Error fetching Staff List", error);
    } finally {
      setBtnLoading(false);
    }
  };

  const updateRequestStatus = (id, status) => {
    Swal.fire({
      text: `This action will cause the request ${status}, Do you want to proceed?`,
      title: "Question",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await PostRequest(
          localStorage.getItem("role") === "accountant"
            ? `/api/approvals/accountant-approval`
            : `/api/approvals/admin-approval`,
          {
            approval_id: id,
            status: status,
          }
        );

        if (response?.status === 422) {
          Swal.fire("Action Failed", response?.data?.message, "error");
        }
        if (response?.status === 400) {
          Swal.fire(
            "Action Failed",
            response?.data?.error_msg || response?.data?.message,
            "error"
          );
        }

        if (response?.status === 201) {
          setReload(!reload);
          Swal.fire(
            "Success",
            `Request has been ${status} successfully`,
            "success"
          );
        }

        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      }
    });
  };

  const [selectedTrans, setSelectedTrans] = useState(null);

  const [screen, setScreen] = useState("approvals");
  const showDetails = (id) => {
    setSelectedTrans(data?.find((item) => item?.id === id));
  };

  useEffect(() => {
    console.log(selectedTrans);
    if (selectedTrans) {
      setScreen("details");
    }
  }, [selectedTrans]);

  return (
    <>
      {screen === "approvals" ? (
        <div>
          <h1 className="h3 mb-3">Approval Request(s)</h1>

          <div className="row">
            <div className="col-xl-12 col-xxl-12 d-flex">
              <div className="w-100">
                <div className="row">
                  <div className="col-md-4">
                    <Card title={"Total Requests"} value={data?.length} />
                  </div>
                  <div className="col-md-4">
                    <Card
                      title={"Total Pending"}
                      value={
                        data?.filter((item) => item.status !== "approved")
                          .length
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <Card
                      title={"Total Approved"}
                      value={
                        data?.filter((item) => item.status === "approved")
                          .length
                      }
                    />
                  </div>
                </div>

                <form onSubmit={loadApprovals}>
                  <div className="d-flex gap-3 mb-3 align-items-center">
                    <div className="form-group">
                      <label htmlFor="from">to</label>
                      <input
                        type="date"
                        className="form-control"
                        name="from"
                        value={userInput.from}
                        onChange={handleUserInput}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="from">From</label>
                      <input
                        type="date"
                        className="form-control"
                        value={userInput.to}
                        name="to"
                        onChange={handleUserInput}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="from">Status</label>
                      <select
                        className="form-control"
                        required
                        name="status"
                        value={userInput?.status}
                        onChange={handleUserInput}
                      >
                        <option value={""}>Select Status</option>
                        <option value={"denied"}>Denied</option>
                        <option value={"approved"}>Approved</option>
                        <option value={"pending_accountant_approval"}>
                          Pending Accountant Approval
                        </option>
                        {localStorage.getItem("role") === "admin" && (
                          <option value={"pending_admin_approval"}>
                            Pending Admin Approval
                          </option>
                        )}
                      </select>
                    </div>
                    <Button
                      cssClasses="btn btn-primary mt-3"
                      buttonText={"Load"}
                      isloading={btnLoading}
                    />
                  </div>
                </form>

                <div className="row">
                  <div className="col-12d-flex">
                    <div className="card flex-fill">
                      <div className="card-header">
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className="card-title mb-0">
                            Disbuersement List
                          </h5>
                        </div>
                      </div>
                      {isloaded ? (
                        <div className="table-responsive">
                          <table className="table table-hover my-0 ">
                            <thead>
                              <tr>
                                <th>Disbursement to</th>
                                <th className="d-none d-md-table-cell">
                                  Amount{" "}
                                </th>
                                <th className="d-none d-md-table-cell">
                                  Status
                                </th>
                            
                                <th className="d-none d-md-table-cell">
                                  Actions
                                </th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item, index) => (
                                <tr key={index}>
                                  <td className="d-none d-md-table-cell text-capitaliz">
                                    {item?.staff?.fullname || "Pending"}
                                  </td>
                                  <td className="d-none d-md-table-cell">
                                    {item?.amount}
                                  </td>
                                  <td>
                                    <span
                                      className={`badge ${
                                        item?.status === "approved"
                                          ? "bg-success"
                                          : "bg-danger"
                                      } `}
                                    >
                                      {item?.status}
                                    </span>
                                  </td>
                              
                                  <td className="d-none d-md-table-cell text-nowrap">
                                    <button
                                      className="btn btn-info"
                                      onClick={() => showDetails(item?.id)}
                                    >
                                      <i
                                        className="fas fa-eye"
                                        title="View Details"
                                      ></i>
                                    </button>
                                    {(item?.status ===
                                      "pending_accountant_approval" ||
                                      item?.status ===
                                        "pending_admin_approval") && (
                                      <>
                                        <button
                                          className="btn btn-success"
                                          onClick={() =>
                                            updateRequestStatus(
                                              item?.id,
                                              "approved"
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-check-circle"
                                            title="Approve Request"
                                          ></i>
                                        </button>
                                        <button
                                          className="btn btn-danger ms-2"
                                          onClick={() =>
                                            updateRequestStatus(
                                              item?.id,
                                              "denied"
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-times"
                                            title="Decline Request"
                                          ></i>
                                        </button>
                                      </>
                                    )}
                                  </td>
                                  <td className="text-capitalize">
                                    {item?.type}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <LoadingPage />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ApprovalDetails setScreen={setScreen} transaction={selectedTrans} />
      )}
    </>
  );
}

export default ManageApprovals;
