import React, { useEffect, useState } from "react";
import { useApiContext } from "../../../../components/context/ApiContext";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/strings/routes";
import Button from "../../../../components/customComponents/Button";

function CheckListForm({ setFoodItems }) {
  const [isloading, setLoading] = useState(false);
  const { PostRequest } = useApiContext();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [staff, setStaff] = useState([]);
  const [food, setFood] = useState([]);
  const { GetRequest } = useApiContext();

  const [userInput, setUserInput] = useState({
    quantity: "",
    staff_id: "",
    food_item_id: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await PostRequest(
      "/api/canteen/food-list/create",
      userInput
    );
    if (response?.status === 200) {
      setFoodItems(response?.data);
      setUserInput((user) => ({
        ...userInput,
        quantity: 0,
        food_item_id: "",
      }));
    }
    if (response?.status === 400) {
      setErrors(response?.data?.error_msg);
    }
    if (response?.status === 422) {
      setErrors(response?.data?.errors);
    }
    setLoading(false);
  };

  async function getFooditems(staff_id) {
    try {
      const response = await GetRequest(
        `/api/canteen/food-list/staff/${staff_id}`
      );
      response?.status === 200 && setFoodItems(response?.data);
      console.log(response);
    } catch (error) {
      console.log("Error fetching Staff List", error);
    }
  }

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "staff_id") {
      getFooditems(e.target.value);
    }
  };

  useEffect(() => {
    async function getStaff() {
      try {
        const response = await GetRequest(`/api/staff/all`);
        setStaff(response?.data);
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    async function foodItems() {
      try {
        const response = await GetRequest(`/api/food-item/all`);
        if (response?.status === 200) {
          setFood(response?.data);
        }
        if (response?.status === 403 || response?.status === 405) {
          navigate(routes?.FORBIDDEN);
        }
      } catch (error) {
        console.log("Error fetching Staff List", error);
      }
    }

    foodItems();
    getStaff();
  }, [GetRequest, navigate]);

  return (
    <div className="col-xl-6 col-xxl-6">
      <div className="w-75">
        <div className="row">
          <div className="card p-3">
            <div className="card-title">Checklist Form</div>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-2">
                <label htmlFor="staff_id">Select Staff:</label>
                <select
                  className="form-control"
                  name="staff_id"
                  value={userInput?.staff_id}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">Select Staff</option>
                  {staff?.map((item, index) => (
                    <option
                      className="text-capitalize"
                      key={index}
                      value={item?.id}
                    >
                      {item?.fullname}
                    </option>
                  ))}
                </select>
                <small className="text-danger">{errors?.staff_id}</small>
              </div>
              <div className="form-group mt-2">
                <label htmlFor="food_item_id">Select Food Item:</label>
                <select
                  className="form-control"
                  name="food_item_id"
                  value={userInput?.food_item_id}
                  onChange={handleUserInput}
                  required
                >
                  <option value="">Select item</option>
                  {food?.map((item, index) => (
                    <option
                      className="text-capitalize"
                      key={index}
                      value={item?.id}
                    >
                      {item?.name}
                    </option>
                  ))}
                </select>
                <small className="text-danger">{errors?.food_item_id}</small>
              </div>
              <div className="form-group mt-2">
                <label htmlFor="description">Enter Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Quntity"
                  step={"any"}
                  required
                  min={1}
                  name="quantity"
                  onChange={handleUserInput}
                  value={userInput?.quantity}
                />

                <small className="text-danger">{errors?.quantity}</small>
              </div>

              <Button
                cssClasses={"btn btn-primary mt-2"}
                buttonText={"Save"}
                isloading={isloading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckListForm;
