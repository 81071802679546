import React from "react";
import routes from "../../../components/strings/routes";
import {
  Book,
  CreditCard,
  DollarSign,
  Home,
  LogOut,
  Menu,
  PenTool,
  Twitch,
  Users,
  FileText,
} from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";

function SideBar({ sideBarCollapsed }) {
  const location = useLocation();
  return (
    <nav
      id="sidebar"
      className={`sidebar js-sidebar ${sideBarCollapsed && "collapsed"}`}
    >
      <div className="sidebar-content js-simplebar">
        <a className="sidebar-brand" href="index.html">
          <span className="align-middle">Trust Pay</span>
        </a>

        <ul className="sidebar-nav">
          <li className="sidebar-header">Admin</li>

          <SidebarItem
            title={"Dashboard"}
            icon={<Home className="align-middle" />}
            path={routes.DASHBOARD}
            pathname={location.pathname}
          />
          <SidebarItem
            title={"Manage Users"}
            icon={<Users className="align-middle" />}
            path={routes.USERS}
            pathname={location.pathname}
          />
          <SidebarItem
            title={"Manage Staff"}
            icon={<Users className="align-middle" />}
            path={routes.STAFF_LIST}
            pathname={location.pathname}
          />
          <li className="sidebar-header">Finance / Payments</li>
          {localStorage.getItem("role") === "domestic" && (
            <>
              <SidebarItem
                title={"Chit Management"}
                icon={<FileText className="align-middle" />}
                path={routes.CHITS}
                pathname={location.pathname}
              />
              <SidebarItem
                title={"Disbursement"}
                icon={<DollarSign className="align-middle" />}
                path={routes.DISBURSEMENTS}
                pathname={location.pathname}
              />
            </>
          )}
          {localStorage.getItem("role") !== "domestic" && (
            <SidebarItem
              title={"Manage Approvals"}
              icon={<Twitch className="align-middle" />}
              path={routes.MANAGE_APPROVALS}
              pathname={location.pathname}
            />
          )}
          <SidebarItem
            title={"Transactions"}
            icon={<CreditCard className="align-middle" />}
            path={routes.TRANSACTIONS}
            pathname={location.pathname}
          />
          <SidebarItem
            title={"Staff Balance(s)"}
            icon={<Book className="align-middle" />}
            path={routes.STAFF_BALANCES}
            pathname={location.pathname}
          />
          <li className="sidebar-header">Food Stuff</li>
          <SidebarItem
            title={"Food Items"}
            icon={<Menu className="align-middle" />}
            path={routes.FOOD_ITEMS}
            pathname={location.pathname}
          />
          {localStorage.getItem("role") === "domestic" && (
            <SidebarItem
              title={"Check List"}
              icon={<PenTool className="align-middle" />}
              path={routes.CHECKLIST}
              pathname={location.pathname}
            />
          )}

          <li className="sidebar-header">Others</li>

          <SidebarItem
            title={"Log Out"}
            icon={<LogOut className="align-middle" />}
            path={routes.INITIAL_ROUTE}
            pathname={location.pathname}
          />
        </ul>
      </div>
    </nav>
  );
}

const SidebarItem = ({ title, icon, path, pathname }) => {
  const navigate = useNavigate();

  return (
    <li
      className={`sidebar-item ${pathname === path && "active"}`}
      onClick={() => navigate(path)}
    >
      <a className="sidebar-link" href={() => navigate(path)}>
        {icon}
        <span className="align-middle">{title}</span>
      </a>
    </li>
  );
};

export default SideBar;
