

const routes={
    INITIAL_ROUTE:'/',
    DASHBOARD:'/dashboard',
    USERS:'/dashboard/users',
    CREATE_USERS:'/dashboard/users/create',
    STAFF_LIST:'/dashboard/staff',
    ADD_STAFF:'/dashboard/staff/add',
    MANAGE_APPROVALS:'/dashboard/approvals',
    CHITS:'/dashboard/chits',
    CHIT_REQUEST:'/dashboard/chits/create',
    PRINT_CHIT:'/chit/print',
    DISBURSEMENTS:'/dashboard/disbursement',
    DISBURSEMENT_REQUEST:'/dashboard/disbursement-request',
    EDIT_DISBURSEMENT_REQUEST:'/dashboard/disbursement-request/:id/edit',
    TRANSACTIONS:'/dashboard/transactions',
    STAFF_BALANCES:'/dashboard/staff-balances',
    STAFF_STATEMENT:'/dashboard/staff/:id/statement',
    FOOD_ITEMS:'/dashboard/food',
    ADD_FOOD_ITEM:'/dashboard/food/create',
    EDIT_FOOD_ITEM:'/dashboard/food/:id/edit',
    CHECKLIST:'/dashboard/checklist',
    FORBIDDEN:'/forbidden',
    BALANCE_WAIVER:'/dashboard/staff/:id/balance-waiver',
}

export default routes;